import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const NudesPage = () => (
  <Layout descriptionText={"Nudes"}>
    <Seo title="nudes" />

    <Gallery categoryName="nudes" />
  </Layout>
);

export default NudesPage;
